import { useState, useEffect, useRef, useContext } from 'react';
import fetchWithBaseUrl from '../lib/fetch';
import { enqueueSnackbar } from 'notistack';

function sendFeedback(chats: Message[], isPositive: boolean, feedback?: string) {
  fetchWithBaseUrl('/api/feedback', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      messages: chats.map(m => ({role: m.userSent ? 'user' : 'assistant', content: m.text})),
      isPositive,
      feedback,
    }),
  }).catch(error => {
    console.error(error);
  });
}

export interface Message {
  text: string;
  userSent: boolean;
  loading?: boolean;
}

export interface ChatContext {
  lang?: string;
  requesting?: boolean;
  messages: Message[];
  addMessage: (message: Message, callback?: () => void) => void;
  importMessages: (messages: Message[]) => void;
  saveConversation: () => Promise<string>;
  regenerateLastResponse: () => void;
  sendFeedback: (chats: Message[], isPositive: boolean, feedback?: string) => void;
  clearMessages: () => void;
}

function useFetchAssistantMessage(key) {
  const [assistantMessage, setAssistantMessage] = useState<string>('');
  useEffect(() => {
    fetchWithBaseUrl('/api/assistant-message?key=' + key)
      .then((res) => res.text())
      .then((data) => {
        setAssistantMessage(data);
      });
  }, [key]);
  return assistantMessage;
}

function summarizeMessages(messages: Message[]) {
  const payload = {messages: messages.map(m => ({role: m.userSent ? 'user' : 'assistant', content: m.text}))};
  return fetchWithBaseUrl('/api/chat/summarize', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  }).then(res => res.text());
}

export default function useChat(key: string, saveConversation): ChatContext {
  const initialText = useFetchAssistantMessage(key)
  const [messages, setMessages] = useState<Message[]>([]);
  const [requesting, setRequesting] = useState<boolean>(false);
  useEffect(() => {
    if (!initialText || messages.length) {
      return;
    }
    setMessages([{ text: initialText, userSent: false }]);
  }, [initialText, messages.length])

  const waitingForResponseRef = useRef<boolean>(false);
  const requestChatApi = async (messages: Message[], {isRegenerating}) => {
    if (waitingForResponseRef.current) {
      return;
    }
    waitingForResponseRef.current = true;
    setRequesting(true);


    // setWaitingForResponse(true);
    const res = await fetchWithBaseUrl(`/api/chat?stream=1&isRegenerating=${isRegenerating}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        messages: messages.map(m => ({role: m.userSent ? 'user' : 'assistant', content: m.text})),
      }),
    }).then(res => {
      if (!res.ok) {
        return Promise.reject(res);
      }
      return res
    }).catch(async (error: Response) => {
      const errorObj = await error.json()
      if (errorObj.statusText === 'History messages too long') {
        enqueueSnackbar('输入消息过长', { variant: 'error'})
        setMessages((prev) => [...prev.slice(0, -1)]);
      }
    }).finally(() => {
      waitingForResponseRef.current = false;
      setRequesting(false);
    });

    if (!res || !res.body) return;
    // console.log(res.body)
    // console.log(res.text())
    const reader = res.body.getReader();
    const decoder = new TextDecoder('utf-8');
    const readChunk = () => reader.read().then(({ value, done }) => {
      if (done) {
        waitingForResponseRef.current = false;
        return Promise.resolve();
      }
      const dataString = decoder.decode(value);
      let lastStart = 0;
      for (const match of Array.from(dataString.matchAll(/}$|}{/g))) {
        const chunk = match.index === undefined ? dataString.slice(lastStart) : dataString.slice(lastStart, match.index + 1);
        lastStart = (match.index || 0) + 1;
        const data = JSON.parse(chunk);
        if (data.error) {
          console.error("Error while generating content: " + data.message);
          return;
        }
        if (data.streamHead) {
          setMessages((prev) => [...prev,  { text: '', userSent: false, loading: true }]);
        } else if (data.content) {
          setMessages((prevMessages) => {
            const updated = prevMessages.slice(0, -1);
            updated.push({ text: prevMessages[prevMessages.length - 1].text + data.content, userSent: false, loading: true });
            return updated;
          });
        }
      }
      return readChunk();
    });
    readChunk().then(() => {
      waitingForResponseRef.current = false;
      setMessages((prev) => [...prev.slice(0, -1),  {...prev[prev.length - 1], loading: false}]);
    });
  };

  const addMessage = (message: Message, callback?: () => void) => {
    if (waitingForResponseRef.current) return;
    callback?.();
    setMessages((prev) => {
      const _messages = [...prev, message];
      requestChatApi(_messages, {isRegenerating: false})
      return _messages
    });
  }

  const regenerateLastResponse = () => {
    if (waitingForResponseRef.current) return;
    setMessages((prev) => {
      const _messages = [...prev.slice(0, -1)];
      requestChatApi(_messages, {isRegenerating: true})
      return _messages
    });
  };

  const saveConversationWithTitle = async () => {
    const title = await summarizeMessages(messages);
    saveConversation(messages, title);
    return title;
  }

  return {
    messages,
    requesting,
    addMessage,
    importMessages: (messages) => setMessages(messages),
    saveConversation: saveConversationWithTitle,
    regenerateLastResponse,
    sendFeedback,
    clearMessages: () => setMessages([]),
  }
}
