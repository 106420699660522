import React from 'react';

interface DownloadIconProps {
  fill?: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ fill = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z" fill={fill} />
    </svg>
  );
};

export default DownloadIcon;
