import React from 'react';

interface IconProps {
  fill?: string;
}

const MenuIcon: React.FC<IconProps> = ({ fill = 'currentColor' }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>menu</title><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" fill={fill}/></svg>;
};

export default MenuIcon;
