// Slideshow.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Slideshow.css';
import { Button } from '@mui/material';

function groupArrayByFive(arr) {
  let result = [];
  for (let i = 0; i < arr.length; i += 5) {
    result.push(arr.slice(i, i + 5));
  }
  return result;
}

const Slideshow = ({ sampleInputs, onSelect }) => {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      infiniteLoop
      autoPlay
      interval={5000}
      transitionTime={1000}
      className="slideshow"
    >
      {groupArrayByFive(sampleInputs).map((group, i) => (
        <div key={i} className="slide">
          {group.map((sampleInput, j) => (
            <Button
              key={j}
              sx={{textTransform: 'unset'}}
              onClick={() => onSelect(sampleInput)}
            >
              {sampleInput}
            </Button>
          ))}
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;
