import React from 'react';

interface IconProps {
  fill?: string;
}

const TrashIcon: React.FC<IconProps> = ({ fill = 'currentColor' }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>trash</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" fill={fill}/></svg>;
};

export default TrashIcon;
