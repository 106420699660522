import React, { useState, ChangeEvent, FormEvent } from "react";
import { TextField, Button, styled, Box } from "@mui/material";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24 0;
`;

const ContactForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setter(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    alert("提交成功！");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <TextField
          size="small"
          variant="filled"
          label="姓名"
          value={name}
          onChange={handleChange(setName)}
          fullWidth
          required
        />
        <TextField
          size="small"
          variant="filled"
          label="公司"
          value={company}
          onChange={handleChange(setCompany)}
          fullWidth
          required
        />
        <TextField
          size="small"
          variant="filled"
          label="手机号"
          value={mobileNumber}
          onChange={handleChange(setMobileNumber)}
          fullWidth
          required
        />
        <Box>
          <Button type="submit" color="secondary" variant="contained" size="small">
            请尽快联系我
          </Button>
        </Box>
      </Container>
    </form>
  );
};

export default ContactForm;
