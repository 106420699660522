import { Button } from '@mui/material';
import _, { isNaN } from 'lodash';
import React, { useEffect } from 'react';

export interface Step {
  index: number;
  question: string;
  yes: number | string | null;
  no: number | string | null;
}

export interface DecisionTree {
  _id: string;
  name: string;
  description: string;
  options: string[];
  steps: Step[];
  createdAt: Date;
}

function getDecisionTreeFromText(text) {
  const lines = text.split('\n').splice(1);
  return lines.map(line => {
    const [index, question, yes, no] = line.split(' | ')
    return {
      index: parseInt(index, 10),
      question,
      yes: parseInt(yes, 10) || yes,
      no: parseInt(no, 10) || no,
    }
  });

}

export default function DecisionTreePerformer({text}: { text: string }) {
  const [currentStep, setCurrentStep] = React.useState<Step>();
  const [option, setOption] = React.useState<string | null>(null);
  const [history, setHistory] = React.useState<Step[]>([]);
  const steps = getDecisionTreeFromText(text)
  useEffect(() => {
    if (steps.length === 0) {
      return;
    }
    setCurrentStep(steps[0]);
  }, [steps.map(s => s.question).join('')]);

  function handleSelect(value) {
    const index = parseInt(value, 10);
    
    setHistory((history) => ([...history, currentStep]))
    if (!isNaN(index)) {
      setCurrentStep(steps.find((step) => step.index === index));
      return;
    }

    setOption(value)
  }

  function popHistory() {
    setOption(null)
    const lastStep = history.pop();
    setCurrentStep(lastStep);
    setHistory(history);
  }

  return (
    <>
      <main>
        {option ? (option) : (
          <>
            <p>{currentStep?.question}</p>
            <Button variant="contained" onClick={() => handleSelect(currentStep?.yes)}>Yes</Button>
            <Button onClick={() => handleSelect(currentStep?.no)}>No</Button>
          </>
        )}
        {history.length > 0 && (
          <Button onClick={popHistory}>上一步</Button>
        )}
      </main>
    </>

  );
}
