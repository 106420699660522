import useLocalStorage from "use-local-storage";
import type { Message } from "./useChat";

interface Conversation {
  title: string;
  messages: Message[];
  savedAt: Date;
}

export function useConversationHistory() {
  const [conversationHistory, setConversationHistory] = useLocalStorage('conversationHistory', '[]');
  const conversationList = (JSON.parse(conversationHistory) as Conversation[]).map((c) => ({
    ...c,
    savedAt: new Date(c.savedAt),
    }));

  const saveConversation = (messages: Message[], title: string) => {
    const newConversationList = [...conversationList, { messages, title, savedAt: new Date() }];
    setConversationHistory(JSON.stringify(newConversationList));
  }

  const deleteConversation = (index: number) => {
    const newConversationList = conversationList.filter((_, i) => i !== index);
    setConversationHistory(JSON.stringify(newConversationList));
  }

  const clearAll = () => {
    setConversationHistory('[]');
  }

  return { conversationList, saveConversation, deleteConversation, clearAll };
}
