import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import { Typography } from "@mui/material";

interface MinData {
  labels: string[];
  prices: number[];
}

const Stock: React.FC = () => {
  const [chartOptions, setChartOptions] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.doctorxiong.club/v1/stock/min?code=688369");

        if (response.data.code === 200) {
          const minData: MinData[] = response.data.data.minData;
          const labels = minData.map((data) => data[0]);
          const prices = minData.map((data) => parseFloat(data[1]));

          setChartOptions({
            chart: {
              type: "line",
              width: 320, // Set the desired width of the chart in pixels
            },
            title: {
              text: "致远互联 688369.SH",
            },
            xAxis: {
              categories: labels,
            },
            yAxis: {
              title: {
                text: "价格",
              },
            },
            series: [
              {
                name: "Stock Price",
                data: prices,
                color: "rgba(75, 192, 192, 0.6)",
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching stock data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Typography variant="subtitle2" color="textSecondary" sx={{py: 1}} gutterBottom>数据获取时间：{new Date().toLocaleString()}</Typography>
      {chartOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
    </div>
  );
};

export default Stock;
