import { Box, IconButton } from "@mui/material";
import { ThumbUpIcon, ThumbDownIcon } from "./ThumbIcons";
import './RateMe.css';

interface RateMeProps {
  onRate: (upDown: boolean) => void;
}

const RateMe: React.FC<RateMeProps> = ({ onRate, ...rest }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...rest}>
      <IconButton size="small" onClick={() => onRate(true)}>
        <ThumbUpIcon fill="#4caf50" />
      </IconButton>
      <IconButton size="small" onClick={() => onRate(false)}>
        <ThumbDownIcon fill="#f44336" />
      </IconButton>
    </Box>
  );
};

export default RateMe;
