import React, { createContext } from 'react';
import { SnackbarProvider, closeSnackbar } from 'notistack';

import { Container, AppBar, Toolbar, Typography, Box, Stack, CssBaseline, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import useLocalStorage from 'use-local-storage';
import ChatUI from './ChatUI';
import BackIcon from './BackIcon';
import ClearIcon from './ClearIcon';
import useChat from './hooks/useChat';
import DownloadIcon from './DownloadIcon';
import ChatDrawer from './Drawer';
import MenuIcon from './icons/MenuIcon';
import ArrowLeftIcon from './icons/ArrowLeftIcon';
import SaveIcon from './icons/SaveIcon';
import { useConversationHistory } from './hooks/useConversationHistory';

export const ChatHistoryContext = createContext({conversationList: [], deleteConversation: (index) => {}, clearAll: () => {}});


function App() {
  const [lang, setLang] = useLocalStorage('lang', 'zh-CN');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [savingChatWithTitle, setSavingChatWithTitle] = React.useState<null | string>(null);
  const [viewportHeight, setViewportHeight] = React.useState(0);
  const { conversationList, deleteConversation, clearAll, saveConversation } = useConversationHistory();
  const chatProps = useChat(lang === 'en' ? 'default-en' : 'default', saveConversation);

  const downloadMessages = () => {
    const file = new Blob([chatProps.messages.map(({text, userSent}) => `${userSent ? '我' : '小致'}: ${text}`).join('\n\n')], {type: 'text/plain'})
    // download file
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = 'chat.txt';
    a.click();
  }

  React.useEffect(() => {
    setViewportHeight(window.visualViewport.height);
    function viewportHandler() {
      setViewportHeight(window.visualViewport.height);
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }, 50);
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }, 600);
    }
    // window.visualViewport.addEventListener("scroll", viewportHandler);
    window.visualViewport.addEventListener("resize", viewportHandler);
    return () => {
      window.visualViewport.removeEventListener("scroll", viewportHandler);
      window.visualViewport.removeEventListener("resize", viewportHandler);
    };
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{horizontal: 'center', vertical: 'top'}}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)} sx={{color: 'primary.contrastText'}}>
          <ClearIcon />
        </IconButton>
      )}>
      <ChatHistoryContext.Provider value={{conversationList, deleteConversation, clearAll}}>
        <Stack sx={{height: viewportHeight}}>
          <CssBaseline />
          <ChatDrawer open={drawerOpen} setOpen={setDrawerOpen} onSelect={chatProps.importMessages} />
          <AppBar position="static" sx={{zIndex: 1001, flexShrink: 0}}>
            <Toolbar sx={{justifyContent: 'flex-start'}}>
              <IconButton onClick={() => window.history.back()} edge="start" size="large" sx={{color: 'primary.contrastText'}}>
                <ArrowLeftIcon />
              </IconButton>
              <Box sx={{mx: 2}}>
                <Box sx={theme => ({[theme.breakpoints.down('md')]: {display: 'none'}})}>
                  <img src="/seeyon-logo.png" alt="seeyon" width="auto" height="24" />
                </Box>
              </Box>
              <Typography variant="h6" sx={{fontWeight: 700, flexGrow: 1, whiteSpace: 'nowrap'}}>
                {lang !== 'en' ? '解决方案' : 'Solutions'}
              </Typography>
              <Tooltip title={lang === 'en' ? 'Clear chat messages' : '清空当前对话'}>
                <IconButton onClick={chatProps.clearMessages} size="large" sx={{color: 'primary.contrastText'}}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={lang === 'en' ? 'Download chat history' : '下载聊天记录'}>
                <IconButton onClick={downloadMessages} size="large" sx={{color: 'primary.contrastText'}}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={lang === 'en' ? 'Save to Chat history' : '保持到聊天记录'}>
                <IconButton onClick={() => chatProps.saveConversation().then(title => setSavingChatWithTitle(title))} size="large" sx={{color: 'primary.contrastText'}}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={lang === 'en' ? 'Chat history' : '聊天记录'}>
                <IconButton onClick={() => setDrawerOpen(prev => !prev)} size="large" edge="end" sx={{color: 'primary.contrastText'}}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Container
            maxWidth="sm"
            sx={{
              // height: [`calc(${viewportHeight}px - 56px)`, `calc(${viewportHeight}px - 64px)`],
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'stretch',
              pb: 2,
              overflowY: 'auto',
            }}
          >
            <ChatUI lang={lang} {...chatProps} />
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{mx: 2, py: 1}}
            >
              <Typography
                variant="caption"
                component="a"
                sx={{color: 'text.secondary', cursor: 'pointer' }}
                onClick={() => {
                  setLang(lang !== 'en' ? 'en' : 'zh-CN');
                  setTimeout(() => {
                    window.location.reload();
                  }, 10);
                }}
              >
                {lang === 'en' ? '中文版' : 'English (英文版)'}
              </Typography>
              <Typography variant="caption" sx={{color: 'text.secondary' }}>
                {lang === 'en' ? 'Service: 400-700-3322' : '服务热线 400-700-3322'}
              </Typography>
            </Stack>
          </Container>
          <Snackbar open={savingChatWithTitle !== null} autoHideDuration={3000} onClose={() => setSavingChatWithTitle(null)}>
            <Alert variant="filled" onClose={() => setSavingChatWithTitle(null)} severity="success" sx={{ width: '100%' }}>
              {lang === 'en' ? `Saved: ${savingChatWithTitle}` : `已保存: ${savingChatWithTitle}`}
            </Alert>
          </Snackbar>
        </Stack>
      </ChatHistoryContext.Provider>
    </SnackbarProvider>
  );
}

export default App;
