import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import remarkGfm from 'remark-gfm';
import {
  styled,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  useTheme,
  IconButton,
  InputAdornment,
  Tooltip,
  Avatar,
  Stack,
  Link,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import SendIcon from './SendIcon';
import { ChatContext } from './hooks/useChat';
import Slideshow from './Slideshow';
import { useSampleInputs } from './hooks/useSampleInputs';
import RateMe from './RateMe';
import RefreshIcon from './icons/RefreshButton';
import './ChatUI.css';
import ContactForm from './plugins/ContactForm';
import FeedbackForm from './plugins/FeedbackForm';
import Stock from './plugins/Stock';
import DecisionTreeSelect from './DecisionTreeSelect';

const placeholderText = '和小致聊聊吧';
const placeholderTextEn = 'Chat with Seeyon AI...';
const sendButtonText = '发送';
const sendButtonTextEn = 'Send';
const botAvatarUrl = '/seeyon-logo-ball.png';

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  flexGrow: 1,
  paddingTop: '1rem',
  paddingBottom: '1rem',
});

interface MessageBubbleProps {
  userSent: boolean;
  avatarUrl?: string;
}

const MessageBubble = styled(Box)<MessageBubbleProps>(({ theme, userSent }) => ({
  // display: 'flex',
  // alignItems: 'center',
  maxWidth: '90%',
  borderRadius: theme.spacing(0.5),
  borderBottomRightRadius: userSent ? 0 : undefined,
  borderTopLeftRadius: userSent ? undefined : 0,
  padding: theme.spacing(1, 1.5),
  margin: theme.spacing(1),
  backgroundColor: userSent ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.05)',
  alignSelf: userSent ? 'flex-end' : 'flex-start',
  color: userSent ? theme.palette.primary.contrastText : theme.palette.text.primary,
  wordWrap: 'break-word',
}))
const MarkdownImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '320px',
  display: 'block',
  margin: '1.5em auto',
  transform: 'scale(1)',
  transition: 'transform 0.3s 0.3s',
  '&:hover': {
    transform: 'scale(1.25)',
    zIndex: 1000,
    position: 'relative',
  },
  // '&:before': {
  //   content: '"抱歉，图片目前不可用"',
  //   display: 'block',
  //   marginBottom: 8,
  // },
  // '&:after': {
  //   content: '"(url: " attr(src) ")"',
  //   display: 'block',
  //   fontSize: 12,
  // }
}));

// Extract plugin/command from the very end of markdown source
// Example plugin: ... __EXAMPLE__
// Return the remaining markdown source and the React component by the plugin
function parseForPlugin(source: string) {
  const pluginRegex = /__([A-Z_]+)__[\s.。]*$/;
  const match = source.match(pluginRegex);
  if (match) {
    const pluginName = match[1];
    // const pluginJson = match[2];
    const remainingSource = source.replace(pluginRegex, '');
    return [remainingSource, pluginName, ''];
  }
  return [source, null, null];
}

function renderWithPlugin(source) {
  const [remainingSource, pluginName, pluginJson] = parseForPlugin(source);
  const markdown = (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={{
      p: Typography,
      img: MarkdownImage as FunctionComponent,
      a: ({ href, children }) => (
        <Link href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </Link>
      ),
    }}>{fixUrlInMarkdown(remainingSource)}</ReactMarkdown>
  );

  if (pluginName === 'CONTACT_FORM') {
    return (
      <React.Fragment>
        {markdown}
        <ContactForm />
      </React.Fragment>
    );
  } else if (pluginName === 'FEEDBACK_FORM') {
    return (
      <React.Fragment>
        {markdown}
        <FeedbackForm />
      </React.Fragment>
    );
  } else if (pluginName === 'CURRENT_STOCK_PRICE') {
    return (
      <React.Fragment>
        {markdown}
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Stock />
        </Box>
      </React.Fragment>
    );
  }

  return markdown;
}

const urlRegex = /\]?\(?\[?(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?\)?/g;

function fixUrlInMarkdown(text: string) {
  // Remove broken mark image link
  const imageLinkRegex = /!\[.*?\]\([^)]*?(\s|$)/;
  if (imageLinkRegex.test(text)) {
    const brokenPattern = /!\[.*?\]\([^)]*?(\s|$)/;
    text = text.replace(brokenPattern, '');
  }

  const result = text.replace(urlRegex, (url) => {
    // check if the url is already in markdown format
    if (url.startsWith("]") || url.startsWith("[") || url.endsWith(")")) {
      return url;
    }
    return `[${url}](${url})`;
  });

  return result

}

const ChatUI: React.FC<ChatContext> = ({addMessage, messages, requesting, regenerateLastResponse, sendFeedback, lang}) => {
  const [input, setInput] = useState<string>('');
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const sampleInputs = useSampleInputs({lang});

  const sendMessage = () => {
    if (!input.trim()) return;
    // return if loading
    addMessage({ text: input, userSent: true }, () => {
      setInput('');
    });
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.altKey) {
      e.preventDefault();
      sendMessage();
    } else if (e.key === 'Enter' && e.altKey) {
      setInput((prev) => prev + '\n');
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const anyUserSentMessageExists = messages.some((message) => message.userSent);
  const firstSaleMessage = messages.find((message) => (message.text && message.text.includes('400-700-3322')))
  const firstContactMessage = messages.find((message) => (message.text && message.text.includes('400-700-8822')))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <ChatContainer ref={chatContainerRef}>
        {messages.map((message, index) => (
          <Stack
            direction="row"
            justifyContent={message.userSent ? 'flex-end' : 'flex-start'}
            sx={{
              position: 'relative',
              '&:hover .rateMe': {
                opacity: 1,
              }
            }}
          >
            {!message.userSent && <Avatar sx={{ marginRight: 1, mt: 1, width: 32, height: 32 }} src={botAvatarUrl} />}
            <MessageBubble key={index} userSent={message.userSent} avatarUrl={!message.userSent ? botAvatarUrl : undefined}>
              {message.text && renderWithPlugin(message.text)}
              {!message.loading  && !message.userSent && message.text && (firstSaleMessage === message || firstContactMessage === message) && (
                <Stack direction="row" spacing={1} sx={{my: 1}}>
                  {message.text.includes('400-700-3322') && (
                    <Button variant="contained" color="secondary" size="small" onClick={() => window.open('https://p.qiao.baidu.com/cps/chat?siteId=3649&userId=377692&siteToken=49c0fa7f96aa0a5fb95c62909d5190a6', '_blank')}>
                      在线咨询
                    </Button>
                  )}
                  {message.text.includes('400-700-8822') && (
                    <Button variant='contained' color="secondary" size='small' onClick={() => window.open('https://support.seeyon.com/index.html?type=cloud&')}>
                      售后服务
                    </Button>
                  )}
                </Stack>
              )}
              {!message.loading && !message.userSent && message.text && (message.text.includes('---|---|---|---')) && (
                <DecisionTreeSelect text={message.text} />
              )}
                
              {!message.userSent && !message.loading && index !== 0 && message.text && (
                <Box sx={{position: 'absolute', right: 8, bottom: 8, opacity: 0}} className="rateMe">
                  <RateMe onRate={isPositive => sendFeedback([messages[index - 1], messages[index]], isPositive)} />
                </Box>
              )}
            </MessageBubble>
          </Stack>
        ))}
        {requesting && (
          // <Stack direction="row" alignItems="center">
          //   <CircularProgress size={32} sx={{mt: 1, color: 'textSecondary'}} color="inherit">
          //     <Avatar sx={{ marginRight: 2, mt: 1, width: 32, height: 32 }} src={botAvatarUrl} />
          //   </CircularProgress>
          // </Stack>
          <Box position="relative" display="inline-flex" sx={{mt: 0.5}}>
            <CircularProgress
              // variant="determinate"
              size={40}
              thickness={4}
            />
            <Box
              top={4}
              left={4}
              // bottom={0}
              // right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Avatar src={botAvatarUrl} sx={{width: 32, height: 32}} alt="AI" />
            </Box>
          </Box>
        )}
      </ChatContainer>

      {!anyUserSentMessageExists && Array.isArray(sampleInputs) && sampleInputs.length && (
        <Stack sx={{flexGrow: 1}} justifyContent="center">
          <Slideshow sampleInputs={sampleInputs} onSelect={setInput}/>
        </Stack>
      )}

      {/* Regenerate last message */}
      {anyUserSentMessageExists && !messages[messages.length - 1]?.userSent && !messages[messages.length - 1].loading && (
        <Stack direction="row" justifyContent="center" sx={{position: 'relative', pt: 2}}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => regenerateLastResponse()}
            startIcon={<RefreshIcon fill="rgba(0, 0, 0, 0.666" />}
            sx={{
              position: 'absolute',
              top: '-50%',
              left: '50%',
              transform: 'translate(-50%, -30%)',
              zIndex: 1,
            }}
          >
            {lang === 'en' ? 'Regenerate' : '重新回复'}
          </Button>
        </Stack>
      )}

      <Box sx={{ padding: theme.spacing(1) }}>
        <TextField
          multiline
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleEnter}
          variant="outlined"
          autoFocus
          placeholder={lang === 'en' ? placeholderTextEn : placeholderText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={lang === 'en' ? sendButtonTextEn : sendButtonText}>
                  <Box>
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={sendMessage}
                      disabled={!input.trim()}
                    >
                      <SendIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{display: 'block'}}
        />
      </Box>
    </Box>
  );
};

export default ChatUI;
