import React from 'react';

interface SendIconProps {
  fill?: string;
}

const SendIcon: React.FC<SendIconProps> = ({ fill = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" fill={fill} />
    </svg>
  );
};

export default SendIcon;
