import { useEffect, useState } from "react";
import fetchWithBaseUrl from '../lib/fetch';

export function useSampleInputs(queryParams: Record<string, string>) {
  const [inputs, setInputs] = useState<string[]>([]);

  useEffect(() => {
    const fetchInputs = async () => {
      const path = '/api/default-questions';
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
      const url = `${path}?${queryString}`;
      const response = await fetchWithBaseUrl(url);
      const data = await response.text();
      setInputs(data.split('\n'));
    };
    fetchInputs();
  }, []);

  return inputs;
}
