import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, Stack, Typography } from '@mui/material';
import ClearIcon from './ClearIcon';
import { relativeTime } from './lib/utils';
import TrashIcon from './icons/TrashIcon';
import { ChatHistoryContext } from './App';

function ChatDrawer({ open, setOpen, onSelect }) {
  const { conversationList, deleteConversation, clearAll } = React.useContext(ChatHistoryContext);
  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(prev => !prev);
    };

  const handleClick = (index: number) => {
    onSelect(conversationList[index].messages);
  };

  const list = () => (
    <Box
      role="presentation"
      sx={{minWidth: 320, maxWidth: '60vw'}}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {conversationList.length !== 0 && (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ml: 2, mr: 1, py: 1}}>
            <Button variant="outlined" color="secondary" endIcon={<TrashIcon />} onClick={() => clearAll()}>清空全部对话</Button>
          </Stack>
        )}
        {conversationList.length === 0 && (
          <Typography variant="subtitle1" align="center" color="textSecondary" sx={{my: 4, px: 2}}>没有历史对话记录</Typography>
        )}
        {conversationList.map((c, index) => (
          <ListItem
            key={index}
            disablePadding
            onClick={() => handleClick(index)}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteConversation(index);
                }}
              >
                <ClearIcon />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText primary={c.title} secondary={c.savedAt ? relativeTime(c.savedAt) : undefined} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <SwipeableDrawer
      open={open}
      anchor="right"
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {list()}
    </SwipeableDrawer>
  );
}

export default ChatDrawer;
