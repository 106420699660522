import React, { useState, ChangeEvent, FormEvent } from "react";
import { TextField, Button, styled, Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
`;

const FeedbackForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [productService, setProductService] = useState<string>("");

  const productsServices = [
    "A8+", "A6+", "G6", "CAP", "M3", "A8-N", "分析云", "Formtalk", "协同人力薪税云", "官网"
  ];

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setter(e.target.value);
  };

  const handleSelectChange = (e) => {
    setProductService(e.target.value as string);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    alert("反馈已提交，感谢您的支持！");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <TextField
          variant="filled"
          label="姓名"
          value={name}
          onChange={handleChange(setName)}
          fullWidth
          size="small"
          required
        />
        <TextField
          variant="filled"
          label="邮箱"
          value={email}
          onChange={handleChange(setEmail)}
          fullWidth
          size="small"
          required
        />
        <FormControl fullWidth required size="small" variant="filled">
          <InputLabel>产品/服务</InputLabel>
          <Select
            value={productService}
            onChange={handleSelectChange}
          >
            {productsServices.map((product, index) => (
              <MenuItem key={index} value={product}>
                {product}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="filled"
          label="反馈"
          value={feedback}
          onChange={handleChange(setFeedback)}
          fullWidth
          size="small"
          required
          multiline
          rows={4}
        />
        <Box>
          <Button type="submit" variant="contained" color="secondary" size="small">
            提交反馈
          </Button>
        </Box>
      </Container>
    </form>
  );
};

export default FeedbackForm;
